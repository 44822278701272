import {useMemo} from 'react';

/**
* A custom hook that memoizes a sorted list of sites
* @returns {array} - list of sites sorted by composite site name
*/
export const useSortedSites = (siteData) => {
  const sortedSiteRecords = useMemo(() => {
    const result = JSON.parse(JSON.stringify( siteData?.sites ));
    for( const s of result ) {
      if( 'composite_site_name' in s ) {
        continue;
      }
      // add composite_site_name since we rely on it for sort
      s.composite_site_name = `${
        s.given_site_id ?? '' } - ${
        s.site_name ?? '' } - ${
        s.pi_name ?? '' }`;
    }
    result.sort( (a, b) => a.composite_site_name.localeCompare(
      b.composite_site_name ));
    return result;

  }, [ siteData ]);

  return {
    sortedSiteRecords,
  };
};
