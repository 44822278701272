import {useContext, useEffect, useState} from 'react';
import {Card, Checkbox} from 'antd';
import {eventTypes} from './constants';
import {AuditTrailStateContext} from './AuditTrailModalContextProvider';

export const EventTypesSelector = (props) => {
  const {protocolId} = props;

  const {
    selectedSite,
    isSiteUpdated,
    selectedEventTypes,
    setSelectedEventTypes,
  } = useContext(AuditTrailStateContext);

  const [ isAllEventTypesSelection, setIsAllEventTypesSelection ] = useState(true);

  useEffect(() => {
    setIsAllEventTypesSelection(selectedEventTypes.length === eventTypes.length);
  }, [selectedEventTypes, setIsAllEventTypesSelection]);

  useEffect(() => {
    if (isSiteUpdated) {
      setSelectedEventTypes(eventTypes.map(e => e.name));
      setIsAllEventTypesSelection(true);
    }
  }, [selectedSite, isSiteUpdated]);

  const onCheckboxAllChangeEventTypes = (isChecked) => {
    setIsAllEventTypesSelection(isChecked);
    setSelectedEventTypes(isChecked ? eventTypes.map(e => e.name) : []);
  };

  const onChangeCheckboxGroupEventTypes = (selectedEventTypes) => {
    setSelectedEventTypes(selectedEventTypes.map(e => e.name));
    setIsAllEventTypesSelection(selectedEventTypes.length === eventTypes.length);
  };

  return (
    <Card
      className='EventTypesSelector'
      title='Select event types'
    >
      <p className='advice'>
        Select desired event types.
      </p>
      <Checkbox
        className='select-all'
        data-clinical-listing-event-types-check-all={protocolId}
        onChange={e => onCheckboxAllChangeEventTypes(e.target.checked)}
        checked={isAllEventTypesSelection}>
        Select All
      </Checkbox>
      <Checkbox.Group
        className='options'
        value={eventTypes.filter(e => selectedEventTypes.includes(e.name))}
        onChange={onChangeCheckboxGroupEventTypes}
      >
        {
          eventTypes.map(e => (
            <p className='option' key={e.name}>
              <Checkbox value={e}>
                {e.name}: <span className='event-type-option-desc'>{e.description}</span>
              </Checkbox>
            </p>
          ))
        }
      </Checkbox.Group>
    </Card>
  );
};
