import { useEffect, useState, useMemo } from 'react';
import { useDataRequest } from '../hook/useDataRequest';
import { DataItemQueryStatus } from './FieldQueries';
import EditSubjectButton from './subject/EditSubjectButton';
import SubjectIdHistory from './subject/SubjectIdHistory';
import { setSitePiSigTabContentVisibility } from '../legacy/LegacyFacade';
import SitePiSignatureContent from './piSignature/SitePiSignatureContent';
import { protocolUserRoleFromUserData } from "../lib/roleUtils";
const SiteSubjectQueryCounts = props => {

  const {
    portalUtils,
    queryCountsItem,
  } = props;

  return portalUtils.attachSubjectListQueryCounts(
    queryCountsItem?.context?.subjectId,
    <DataItemQueryStatus queryCounts={queryCountsItem?.count} />
  );
};

const SiteSubjectListQueryCounts = props => {

  const {
    portalUtils,
    siteContext,
    pageSubjectList,
  } = props;


  const queryCountRequest = useDataRequest({

    endpoint: 'gui/queryCountList',
    context: siteContext,
    paging: {
      maxResults: null,
    },
    sort: {
      isAscending: true,
    },
    filter: {},
    config: { enabled: !!siteContext?.siteId, retryOnMount: true },
  });

  if(! queryCountRequest?.data?.items
     || queryCountRequest?.data?.items.length === 0) {
    return null;
  }

  return queryCountRequest?.data?.items
    .filter( item => Object.keys(pageSubjectList?.subjectIdMap)?.includes(
                                                    item.context.subjectId))
    .map( item => (
      <SiteSubjectQueryCounts
        key={item?.context?.subjectId}
        portalUtils={ portalUtils }
        queryCountsItem={ item }
      />));
};

const SiteEditSubjectButtons = (props) => {
  const {
    portalUtils,
    siteContext,
    pageSubjectList,
  } = props;

  return Object.entries(pageSubjectList?.subjectIdMap)?.map(([subjectId, displayId]) => (
    portalUtils.attachSubjectEditButtons(
      subjectId,
      <EditSubjectButton
        prevSubjectDisplayName={displayId}
        subjectId={subjectId}
        siteContext={siteContext}
      />
    )
  ));
}

const SiteSubjectIdHistories = (props) => {
  const {
    portalUtils,
    siteContext,
    pageSubjectList,
    subjectIdHistories,
    subjectIdHistoryDraw,
  } = props;

  return Object.keys(pageSubjectList?.subjectIdMap)?.map(
    (subjectId) =>
      subjectIdHistoryDraw?.[subjectId] &&
      portalUtils.attachSubjectIdHistory(
        subjectId,
        <SubjectIdHistory
          siteContext={siteContext}
          subjectId={subjectId}
          subjectIdHistory={subjectIdHistories?.[subjectId]}
        />
      )
  );
}
const SitePiSignatureContentWrapper = ({
  siteContext,
  portalUtils,
  piSigData,
  userRoles,
  userEmail,
}) => {

  if(!piSigData) {
    return null;
  }

  const context = {};
  return portalUtils.attachPiSignatureSiteTab(context,
    <SitePiSignatureContent
      siteContext={siteContext}
      piSigData={piSigData}
      userRoles={userRoles}
      userEmail={userEmail}
    />
  );
};
const shouldDisplaySitePiSignature = (siteContext, protocolData) => {

  const protocolBranch = siteContext.protocolBranch;
  const protocolDetails = protocolData.protocol_list
    .find(({ protocol_branch }) => protocol_branch === protocolBranch)
    ?.study?.protocolDetails;
  return protocolDetails?.display_site_pi_signature || false;
};
export const SiteComponents = props => {

  const { classic, portalUtils } = props;

  const [ siteInfo, setSiteInfo ] = useState(null);
  const [ pageSubjectList, setPageSubjectList ] = useState(null);
  const [ subjectIdHistories, setSubjectIdHistories ] = useState(null);
  const [ subjectIdHistoryDraw, setSubjectIdHistoryDraw ] = useState(null);
  const [ piSigData, setPiSigData ] = useState(null);
  const [ protocolData, setProtocolData ] = useState(null);
  const [ userRoles, setUserRoles ] = useState(null);

  useEffect(() => {

    if (!classic) {
      return;
    }
    classic.subscribe('SiteComponents', 'enterSiteContext', setSiteInfo);
    classic.subscribe('SiteComponents', 'leaveSiteContext', setSiteInfo);
    classic.subscribe('SiteComponents',
                      'subjectListPageDraw', setPageSubjectList);
    classic.subscribe('SiteComponents', 'subjectIdHistories', (message) => {
      setSubjectIdHistories(oldHistory => {
        return {
          ...oldHistory,
          [message.subject?.subject_id]: message.subject?.display_id,
        };
      });
    });

    classic.subscribe(
      'SiteComponents',
      'subjectIdHistoryDraw',
      (message) => {
        setSubjectIdHistoryDraw(oldHistory => {
          return {
            ...oldHistory,
            [message.subjectId]: message.isHistoryShown,
          };
        });
      }
    );
    classic.subscribe('SiteComponents', 'showSitePiSigTabContent', (message) => {
      setPiSigData(message);
    });
    classic.subscribe('SiteComponents', 'leaveSitePiSigTabContent', () => {
      setPiSigData(null);
    });
    classic.subscribe('SiteComponents', 'protocolData', setProtocolData);
    classic.subscribe('SiteComponents', 'protocolListData', setUserRoles);
  }, [ classic, setUserRoles ]);

  const siteContext = useMemo(
    () => ({
      protocolId: siteInfo?.protocol_id,
      siteId: siteInfo?.siteId,
      protocolBranch: siteInfo?.protocolVersionName,
      protocolInstance: siteInfo?.protocolInstance,
    }),
    [
      siteInfo?.protocol_id,
      siteInfo?.siteId,
      siteInfo?.protocolInstance,
      siteInfo?.protocolVersionName,
    ]
  );

  const protocolUserRole = useMemo(() => {
    return protocolUserRoleFromUserData(userRoles, siteInfo?.protocol_id);
  }, [userRoles, siteInfo?.protocol_id]);

  const userEmail = useMemo(() => {
    return userRoles?.user?.email;
  }, [userRoles]);

  if(!siteInfo) {

    if(pageSubjectList){
      setPageSubjectList(() => null);
    }
    return null;
  }

  if( !pageSubjectList ) {
    return null;
  }

  const displaySitePISignature = shouldDisplaySitePiSignature(siteContext, protocolData);

  if(!displaySitePISignature){
    setSitePiSigTabContentVisibility(false);
  }

  return (
    <>
      <SiteEditSubjectButtons 
        portalUtils={portalUtils}
        siteContext={siteContext}
        pageSubjectList={pageSubjectList}
      />
      <SiteSubjectIdHistories
        portalUtils={portalUtils}
        siteContext={siteContext}
        pageSubjectList={pageSubjectList}
        subjectIdHistories={subjectIdHistories}
        subjectIdHistoryDraw={subjectIdHistoryDraw}
      />
      <SiteSubjectListQueryCounts
        portalUtils={portalUtils}
        siteContext={siteContext}
        pageSubjectList={pageSubjectList}
      />
      {displaySitePISignature  && <SitePiSignatureContentWrapper
        siteContext={siteContext}
        portalUtils={portalUtils}
        piSigData={piSigData}
        userRoles={[protocolUserRole]}
        userEmail={userEmail}
      />}
    </>
  );


};
