import {useContext} from "react";
import {Card, Select, Radio} from 'antd';
import {AuditTrailStateContext} from './AuditTrailModalContextProvider';
import {useSortedSites} from '../hooks/useSortedSites';

export const SiteSelector = (props) => {
  const {siteData} = props;

  const { selectedSite, setSelectedSite, setIsSiteUpdated } = useContext(AuditTrailStateContext);

  const { sortedSiteRecords } = useSortedSites(siteData);

  const handleRadioOnChange = (e) => {
    const siteId = e.target.value;
    if (selectedSite !== siteId) {
      setIsSiteUpdated(true);
    }
    setSelectedSite(siteId);
  };

  const handleSelectOnChange = (siteId) => {
    if (selectedSite !== siteId) {
      setIsSiteUpdated(true);
    }
    setSelectedSite(siteId);
  };

  return (
    <Card
      className='SiteSelector'
      title='Select site'
    >
      <p className='advice'>
        Select only one site per download.
      </p>
      <Select
        showSearch
        placeholder="Select a site"
        optionFilterProp="label"
        onChange={handleSelectOnChange}
        options={sortedSiteRecords.map(r => ({ value: r.site_id, label: r.composite_site_name}))}
        value={
          selectedSite ?
          sortedSiteRecords.filter(r => r.site_id === selectedSite)[0].composite_site_name :
          null
        }
      />
      <Radio.Group
        onChange={handleRadioOnChange}
        value={selectedSite}
      >
        {
          sortedSiteRecords.map(r => (
            <Radio value={r.site_id} key={r.site_id}>{r.composite_site_name}</Radio>
          ))
        }
      </Radio.Group>
    </Card>
  );
};
