class HandleFetchError extends Error {}
class HttpResponseError extends Error {}

export const handleFetch = async (fetchOptions) => {
  const { endpoint, ...options } = fetchOptions;

  try {

    const response = await fetch(endpoint, {
      ...options,
    });

    if(!response.ok){
      throw new HttpResponseError(`Received error from HTTP call with status ${response.status} and message: ${response.message}`);
    }

    return response;

  } catch (error) {
    if (error instanceof HttpResponseError) {
      throw error
    }
    throw new HandleFetchError(`Error handling fetch for request with options: ${JSON.stringify(fetchOptions)}`);
  }
};
