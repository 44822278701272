import { useState, useEffect, useCallback } from 'react';
import deepEqual from 'deep-equal';

import { DataItemQueryStatus } from './FieldQueries';
import { useDataRequest } from '../hook/useDataRequest';
import {
  attachProtocolNavigation,
  ProtocolListDetachListener,
} from '../legacy/PortalUtils';
import { ProtocolNavigation } from './ProtocolNavigation';
import {ClinicalListingModal} from './reportModals/clinicalListing/ClinicalListingModal';
import {
  AuditTrailModalContextProvider
} from './reportModals/auditTrail/AuditTrailModalContextProvider';

const ProtocolSiteItemQueryCounts = props => {

  const {
    portalUtils,
    siteQueryCountsItem,
  } = props;

  return (
      portalUtils.attachSiteListQueryCounts(
        siteQueryCountsItem?.context?.siteId,
        <DataItemQueryStatus queryCounts={siteQueryCountsItem?.count} />
      ));
};

const ProtocolSiteListQueryCounts = props => {

  const {
    portalUtils,
    siteData,
    isSiteListSelected,
  } = props;

  const queryCountRequest = useDataRequest({
    endpoint: 'gui/queryCountList',
    context: {
      protocolId: siteData?.protocol_id,
    },
    paging: {
      maxResults: null,
    },
    sort: {
      isAscending: true,
    },
    filter: {},
    config: { enabled: !!siteData?.protocol_id },
  });

  if( !isSiteListSelected || !siteData ) {
    return null;
  }

  if(  !queryCountRequest?.data?.items
     || queryCountRequest?.data?.items.length === 0) {
    return null;
  }

  if(
     queryCountRequest.data.items[0].context.protocolId // last request protocol
     !== siteData.protocol_id // present protocol
    ) {
    return null;
  }

  return queryCountRequest?.data?.items.map( siteQueryCountsItem => (
      <ProtocolSiteItemQueryCounts
        key={siteQueryCountsItem?.context?.siteId}
        portalUtils={portalUtils}
        siteQueryCountsItem={siteQueryCountsItem}
      />
  ));

};

const ProtocolNavigationContainer = props => {
  const {
    userData,
    siteData,
    setProtocolNavigationSelection,
    onDetach,
    protocolData,
  } = props;

  if(!siteData) {
    return null;
  }

  return attachProtocolNavigation(
  <ProtocolListDetachListener
    onDetach={onDetach}
  >
    <ProtocolNavigation
      userData={userData}
      siteData={siteData}
      protocolData={protocolData}
      setProtocolNavigationSelection={setProtocolNavigationSelection}
    />
  </ProtocolListDetachListener>
  );
};

export const ProtocolComponents = props => {

  const { classic, portalUtils } = props;

  const [ userData, setUserData ] = useState(null);
  const [ siteData, setSiteData ] = useState(null);
  const [ displayClinicalListing, setDisplayClinicalListing ] = useState(false);
  const [ protocolData, setProtocolData ] = useState( null );
  const [ protocolNavigationSelection, setProtocolNavigationSelection ]
        = useState( 'sites' );
  const setUserDataByValue = useCallback(fullNewUserData => {

    const { aid, ...newUserData } = fullNewUserData; // strip 'aid'

    if(deepEqual(userData, newUserData)) {
      return;
    }
    setUserData( () => newUserData);

  }, [ userData ]);
  // Used to rerender ProtocolSiteListQueryCounts when a new site list DataTable page is drawn
  const [, updateSiteListPageDraw ] = useState( null );
  const [isAuditTrailModalOpen, setIsAuditTrailModalOpen] = useState(false);

  useEffect(() => {
    if(!classic) {
      return;
    }
    classic.subscribe('ProtocolComponents', 'protocolListData',
                                            setUserDataByValue);
    classic.subscribe('ProtocolComponents', 'siteData', setSiteData);
    classic.subscribe('ProtocolComponents', 'displayClinicalListing', setDisplayClinicalListing);
    classic.subscribe('ProtocolComponents', 'displayAuditTrailReport', () => setIsAuditTrailModalOpen(true));
    classic.subscribe('ProtocolComponents', 'protocolData', setProtocolData);
    classic.subscribe('ProtocolComponents',
    'siteListPageDraw', updateSiteListPageDraw);

  }, [
    classic,
    setUserDataByValue,
    ]);

  if(!protocolData) {
    return null;
  }

  return (
    <>
      <ProtocolSiteListQueryCounts
        portalUtils={portalUtils}
        siteData={siteData}
        isSiteListSelected={protocolNavigationSelection === 'sites'}
      />
      <ClinicalListingModal
        siteData={siteData}
        userData={userData}
        protocolData={protocolData}
        portalUtils={portalUtils}
        displayClinicalListing={displayClinicalListing}
      />
      <AuditTrailModalContextProvider
        protocolData={protocolData}
        siteData={siteData}
        isAuditTrailModalOpen={isAuditTrailModalOpen}
        setIsAuditTrailModalOpen={setIsAuditTrailModalOpen}
      />
      <ProtocolNavigationContainer
        userData={userData}
        siteData={siteData}
        protocolData={protocolData}
        setProtocolNavigationSelection={setProtocolNavigationSelection}
        onDetach={ () => {
          // prevent ProtocolSiteListQueryCounts render
          setProtocolNavigationSelection( () => null );
          // prevent ProtocolNavigationContainer render
          setSiteData( () => null );
        }}
      />
    </>
  );
};
