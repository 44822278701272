import {useContext, useEffect, useState} from 'react';
import {Card, Checkbox, Select} from 'antd';
import {errorHandlerWrapper, prepareSubjectsForDisplay} from '../utility';
import {AuditTrailStateContext} from './AuditTrailModalContextProvider';

export const SubjectsSelector = (props) => {
  const {
    protocolId,
    setIsDataDownloadInProgress,
  } = props;

  const {
    selectedSite,
    isSiteUpdated,
    selectedSubjects,
    setSelectedSubjects
  } = useContext(AuditTrailStateContext);

  const [ subjectRecords, setSubjectRecords ] = useState([]);
  const [ isAllSubjectsSelection, setIsAllSubjectsSelection ] = useState(false);
  const [error, setError] = useState(null);

  if (error) {
    throw error;
  }

  useEffect(() => {
    setIsAllSubjectsSelection(selectedSubjects.length === subjectRecords.length);
  }, [selectedSubjects, subjectRecords, setIsAllSubjectsSelection]);

  useEffect(() => {
    if (isSiteUpdated) {
      setSelectedSubjects([]);
      setIsAllSubjectsSelection(false);
    }
  }, [selectedSite, isSiteUpdated]);

  useEffect( () => {
    const fetchSubjects = async (protocolId, siteId) => {
      await errorHandlerWrapper(prepareSubjectsForDisplay, [setIsDataDownloadInProgress, [siteId], protocolId, setSubjectRecords]);
    };
    fetchSubjects(protocolId, selectedSite).catch(err => setError(err));
    return () => setSubjectRecords([]); // clean up on detach
  }, [
    selectedSite,
    protocolId,
    setIsDataDownloadInProgress,
  ]);

  const onSelectOnChange = (subjectId) => {
    const selectedSubjectsSet = new Set(selectedSubjects);
    if (selectedSubjectsSet.has(subjectId)) {
      // Deselect
      selectedSubjectsSet.delete(subjectId);
    } else {
      // Select
      selectedSubjectsSet.add(subjectId);
    }
    const updatedSelectedSubjects = [...selectedSubjectsSet];
    setSelectedSubjects(updatedSelectedSubjects);
    setIsAllSubjectsSelection(updatedSelectedSubjects.length === subjectRecords.length);
  };

  const onClearSubjects = () => {
    setSelectedSubjects([]);
    setIsAllSubjectsSelection(false);
  };

  const onDeselectSubjects = (subjectName) => {
    const subject = subjectRecords.filter(r => r.display_id[0].value === subjectName)[0];
    const remainingSelectedSubjected = selectedSubjects.filter(r => r !== subject.subject_id);
    setSelectedSubjects(remainingSelectedSubjected);
    setIsAllSubjectsSelection(remainingSelectedSubjected.length === subjectRecords.length);
  };

  const onCheckboxAllChangeSubjects = (isChecked) => {
    setIsAllSubjectsSelection(isChecked);
    setSelectedSubjects(isChecked ? subjectRecords.map(subject => subject.subject_id) : []);
  };

  /*
  Although the "options" prop of the Select component performs better than the Option JSX components, we use the latter
  here to display which options are selected in the dropdown. We need to do this because the "value" prop
  of the Select component overrides the dropdown list's styling and doesn't highlight the selected options.
   */
  const dropdownOptionObjects = subjectRecords.map(r => ({ value: r.subject_id, label: r.display_id[0].value}));
  const dropdownOptionComponents = dropdownOptionObjects.map(option => {
    const isSelected = selectedSubjects.includes(option.value);
      return (
          <Select.Option
              className={`${isSelected ? 'ant-select-item-option-selected' : ''}`}
              value={option.value}
          >
            {option.label}
          </Select.Option>
      );
    }
  );
  
  return (
    <Card
      className='SubjectsSelector'
      title='Select subjects'
    >
      <p className='advice'>
        Select one or more subjects. There will be one file per subject.
      </p>
      <Checkbox
          className='select-all'
          data-audit-trail-subject-check-all={protocolId}
          onChange={e => onCheckboxAllChangeSubjects(e.target.checked)}
          checked={isAllSubjectsSelection}>
        Select All
      </Checkbox>
      <Select
        mode="multiple"
        allowClear
        optionFilterProp="label"
        placeholder="Select subjects"
        onSelect={onSelectOnChange}
        value={
          selectedSubjects ?
          subjectRecords.filter(r => selectedSubjects.includes(r.subject_id)).map(r => r.display_id[0].value) :
          []
        }
        onClear={onClearSubjects}
        onDeselect={onDeselectSubjects}
        filterOption={(input, option) => {
          // Filter the dropdown options that contain the input search text
          const boolArray = Object.entries(option).map(([key, value]) => {
            // The "children" key contains the option's label
            if (key === 'children') {
              return value.toLowerCase().includes(input.toLowerCase());
            }
          });
          return boolArray.indexOf(true) > -1;
        }}
        maxTagCount={'responsive'}
      >
        {dropdownOptionComponents}
      </Select>
    </Card>
  )
};
