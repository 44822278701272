import {createContext, useState} from 'react';
import {eventTypes} from './constants';
import {AuditTrailReportModalContainer} from './AuditTrailReportModalContainer';

export const AuditTrailStateContext = createContext(null);

export const AuditTrailModalContextProvider = (props) => {
  const [ selectedSite, setSelectedSite ] = useState(null);
  const [ isSiteUpdated, setIsSiteUpdated ] = useState( false );
  const [ selectedSubjects, setSelectedSubjects ] = useState([]);
  const [ selectedEventTypes, setSelectedEventTypes ] = useState(eventTypes.map(e => e.name));
  const [ selectedStartDate, setSelectedStartDate ] = useState(null);
  const [ selectedEndDate, setSelectedEndDate] = useState(null);
  const [ isEntireHistorySelected, setIsEntireHistorySelected ] = useState(false);

  return (
    <AuditTrailStateContext.Provider
      value={{
        selectedSite,
        setSelectedSite,
        isSiteUpdated,
        setIsSiteUpdated,
        selectedSubjects,
        setSelectedSubjects,
        selectedEventTypes,
        setSelectedEventTypes,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        isEntireHistorySelected,
        setIsEntireHistorySelected,
      }}
    >
      <AuditTrailReportModalContainer {...props} />
    </AuditTrailStateContext.Provider>
  );
};
