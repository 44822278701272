import {useCallback, useContext} from 'react';
import {Button, Modal} from 'antd';
import JSZip from 'jszip';
import {FileDownloadInProgressModal} from '../FileDownloadInProgressModal';
import {AuditTrailDialog} from './AuditTrailDialog';
import {useModalStatus} from '../hooks/useModalStatus';
import {aggregateFileChunks, errorHandlerWrapper} from '../utility';
import {getAuditTrailReport} from '../../../lib/fetchers/restApiHttpFetchers';
import {AuditTrailStateContext} from './AuditTrailModalContextProvider';
import {
  displayNotification,
  NotificationTypes
} from '../../../lib/notificationUtils';
import './AuditTrailModal.scss';

export const AuditTrailReportModal = (props) => {
  const {
    protocolData,
    siteData,
    setIsAuditTrailModalOpen,
  } = props;

  const {
    selectedSite,
    selectedSubjects,
    selectedEventTypes,
    selectedStartDate,
    selectedEndDate,
    isEntireHistorySelected,
    setSelectedSite,
    setSelectedSubjects,
    setSelectedEventTypes,
    setSelectedStartDate,
    setSelectedEndDate,
    setIsEntireHistorySelected,
    setIsSiteUpdated,
  } = useContext(AuditTrailStateContext);

  const {
    step,
    setStep,
    isDataDownloadInProgress,
    setIsDataDownloadInProgress,
    isFileDownloadInProgress,
    error,
    setError,
    turnOffFileDownloadingFlags,
    turnOnFileDownloadingFlags,
    handleNext,
    handlePrevious,
  } = useModalStatus();

  const protocolId = protocolData.protocol_list[0].protocol_id;

  const downloadFile = (blob) => {
    const objectUrl = window.URL.createObjectURL(blob);
    const protocolNumber = siteData.protocol_number.replace(/\s/g, '');
    const date = (new Date()).toISOString();
    const fileName = `${protocolNumber}-AuditLogReport-${date}.zip`;
    const a = document.createElement('a');
    a.href = objectUrl;
    a.style = 'display: none';
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(objectUrl);
    document.body.removeChild(a);
  }

  const downloadFileOrShowNotification = useCallback(async (data, numberSelectedSubjects) => {
    const blob = new Blob(data, { type: 'application/zip' });
    const zip = new JSZip();
    const content = await zip.loadAsync(blob);
    const fileCount = Object.keys(content.files).length;
    if (fileCount === 0) {
      displayNotification(
        'There is no audit history for the parameters indicated.',
        NotificationTypes.Info,
        null,
        8,
      );
    } else {
      if (fileCount < numberSelectedSubjects) {
        displayNotification(
          'There is no audit history for the indicated parameters for some subjects. There is no report to download for these subjects.',
          NotificationTypes.Info,
          NotificationTypes.Info,
          8,
        );
      }
      downloadFile(blob);
      displayNotification(
        'Your Audit Trail Report has been downloaded.',
        NotificationTypes.Success,
        NotificationTypes.Success,
        8,
      );
    }
  },[siteData]);

  const downloadAuditTrailReport = useCallback(async (
    { name, numberSelectedSubjects }
  ) => {
    const input = {
      route: name,
      context: { protocolId }, // needed for backend stream responder
      protocolId: protocolId,
      siteId: selectedSite,
      subjectIds: selectedSubjects,
      eventTypes: selectedEventTypes,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      entireHistory: isEntireHistorySelected,
      responseFile: true,
    };

    const response = await getAuditTrailReport(input);
    if (response.status === 409) {
      displayNotification(
        'One of your reports is currently generating.' +
        'Once that completes or after 15 minutes have passed, you may download' +
        'another report.',
        NotificationTypes.Warning,
      );
    } else if (response.status !== 200 || !response.ok) {
      throw new Error(response.error);
    } else {
      const readableStream = response.body;
      const file = await aggregateFileChunks(readableStream.getReader());
      await downloadFileOrShowNotification(file, numberSelectedSubjects);
    }
  }, [
    protocolId,
    selectedSite,
    selectedSubjects,
    selectedEventTypes,
    selectedEndDate,
    isEntireHistorySelected,
  ]);

  const onGenerateReportAndDownload = async (numberSelectedSubjects) => {
    const getAuditTrailData = async () => {
      turnOnFileDownloadingFlags();
      await errorHandlerWrapper(downloadAuditTrailReport, {
        name: '/report/audit_trail',
        numberSelectedSubjects,
      });
      turnOffFileDownloadingFlags();
      resetModal();
      setIsAuditTrailModalOpen(false);
    };
    getAuditTrailData().catch(err => {
      displayNotification(
        'Your Audit Trail Report download failed. Please reduce the scope of the report and try again.',
        NotificationTypes.Error,
        NotificationTypes.Error,
        8,
      );
      setError(err)
    });
  };

  const resetModal = () => {
    setStep(0);
    setSelectedSite(null);
    setSelectedSubjects([]);
    setSelectedEventTypes([]);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setIsEntireHistorySelected(false);
  };

  const handleCancel = (resetModal) => {
    resetModal();
    setIsAuditTrailModalOpen(false);
    turnOffFileDownloadingFlags();
  };

  const handleClose = () => {
    setIsAuditTrailModalOpen(false);
  };

  const handleAuditTrailPrevious = () => {
    setIsSiteUpdated(false);
    handlePrevious();
  };

  if (error) {
    throw error;
  }

  if (isFileDownloadInProgress) {
    return (
      <FileDownloadInProgressModal
        isFileDownloadInProgress={isFileDownloadInProgress}
        protocolId={protocolId}
        handleClose={handleClose}
        title={'Audit Trail report is being generated.'}
      />
    );
  }

  const isNextButtonDisabled = (step === 0 && !selectedSite) ||
    (step === 1 && selectedSubjects.length === 0) ||
    (step === 2 && selectedEventTypes.length === 0) ||
    step === 3 ||
    isDataDownloadInProgress;

  const isGenerateReportButtonDisabled = isDataDownloadInProgress ||
    ((selectedStartDate === null || selectedEndDate === null) && !isEntireHistorySelected);

  return (
    <Modal
      className='AuditTrailReportModal'
      maskClosable={false}
      confirmLoading={isDataDownloadInProgress}
      footer={[
        <Button
          data-audit-trail-prev-button-id={protocolId}
          key='prev'
          onClick={handleAuditTrailPrevious}
          disabled={isDataDownloadInProgress || step === 0}
        >
          Previous
        </Button>,
        <Button
          key='next'
          data-audit-trail-next-button-id={protocolId}
          onClick={handleNext}
          disabled={isNextButtonDisabled}
        >
          Next
        </Button>,
        <Button
          key='back'
          data-audit-trail-cancel-button-id={protocolId}
          onClick={() => handleCancel(resetModal)}
          disabled={isDataDownloadInProgress}
        >
          Cancel
        </Button>,
        <Button
          disabled={isGenerateReportButtonDisabled}
          key='submit'
          data-audit-trail-generate-report-button-id={protocolId}
          type='primary'
          onClick={() => onGenerateReportAndDownload(selectedSubjects.length)}
        >
          Generate Report
        </Button>
      ]}
      title={ <h2>Audit Trail Report</h2> }
      open={true}
      onOk={() => onGenerateReportAndDownload(selectedSubjects.length)}
      onCancel={() => handleCancel(resetModal)}
    >
      <AuditTrailDialog
        protocolId={protocolId}
        siteData={siteData}
        step={step}
        isDataDownloadInProgress={isDataDownloadInProgress}
        setIsDataDownloadInProgress={setIsDataDownloadInProgress}
      />
    </Modal>
  );
};
