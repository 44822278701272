import {AuditTrailReportModal} from './AuditTrailReportModal';

export const AuditTrailReportModalContainer = (props) => {
  const { isAuditTrailModalOpen, ...remainingProps } = props;

  if (!isAuditTrailModalOpen) {
    return null;
  }

  return (
    <AuditTrailReportModal {...remainingProps} />
  );
};
