import {useState} from 'react';

/**
 * Custom hook to share common state and dispatch functions across different
 * report modals
 * @returns {object} - all the variables needed by modals to manage their own state
 */
export const useModalStatus = () => {
  const [step, setStep] = useState(0);
  const [isDataDownloadInProgress, setIsDataDownloadInProgress] = useState(false)
  const [isFileDownloadInProgress, setIsFileDownloadInProgress] = useState(false);
  const [error, setError] = useState(false);

  /**
   * Update state to reflect there is no file download in progress
   * @returns {void}
   */
  const turnOffFileDownloadingFlags = () => {
    setIsFileDownloadInProgress(false);
    setIsDataDownloadInProgress(false);
  };

  /**
   * Update state to reflect there is a file download in progress
   * @returns {void}
   */
  const turnOnFileDownloadingFlags = () => {
    setIsFileDownloadInProgress(true);
    setIsDataDownloadInProgress(true);
  };

  /**
   * Update state to move to the next modal page
   * @returns {void}
   */
  const handleNext = () => setStep(step + 1);

  /**
   * Update state to move to the previous modal page
   * @returns {void}
   */
  const handlePrevious = () => setStep(step - 1);

  return {
    step,
    setStep,
    isDataDownloadInProgress,
    setIsDataDownloadInProgress,
    isFileDownloadInProgress,
    error,
    setError,
    turnOffFileDownloadingFlags,
    turnOnFileDownloadingFlags,
    handleNext,
    handlePrevious,
  };
};
