import React from "react";
import { Button, Form, Input, Radio, Alert } from "antd";

import "./SitePiSignatureContent.scss";

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  return new Date(dateString)
    .toLocaleDateString(undefined, options)
    .replace(",", "");
};

const RenderPiSignatureForm = ({
  handleSign,
  handleCreateKey,
  hasPisigSignPermission,
  userEmail,
  userKey,
  publicKeyId,
  subjectId,
  lastKeyGeneratedTime,
  showPasswordForm,
  handleForgetPassword,
}) => {
  if (!hasPisigSignPermission) {
    return <Alert message="You do not have permission to sign." type="error" />;
  }

  return (
    <>
      {userKey && !showPasswordForm ? (
        <Form
          id="sign_key_input"
          layout="vertical"
          onFinish={async (values) => {
            await handleSign({
              ...values,
              lastKeyGeneratedTime,
              subjectId,
            });
          }}
        >
          <Form.Item
            label="Your Email"
            name="email"
            rules={[
              {
                validator: (_, value) => {
                  if (userEmail && value.trim() !== userEmail) {
                    return Promise.reject(new Error('Please enter the email address associated with your profile.'));
                  }
                  return Promise.resolve();
                },
              },
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address.",
              },
            ]}
          >
            <Input size="large" autoComplete="off" />
          </Form.Item>
          <Form.Item
            label="Your Key Password"
            name="key_password"
            rules={[
              {
                validator(_, value) {
                  if (!value || value.trim() === "") {
                    return Promise.reject(
                      new Error("Please enter your secret key's password."),
                    );
                  }
                  return Promise.resolve();
                },
                key: "validator-key-password",
              },
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <p>
            Please enter your secret key's password to sign (ECDH Public Key ID:{" "}
            {publicKeyId}) generated on {formatDate(lastKeyGeneratedTime)}.
          </p>
          <p>
            Please note that all the attempts (successful or failed) to sign
            subjects will be logged for security and audit purposes.
          </p>
          <p>
            If you enter your password incorrectly 3 times it will send an alert
            to the organization management for security and compliance purposes.
          </p>
          <p>
            Forgot your password?
            <Button type="link" onClick={handleForgetPassword}>
              Click Here
            </Button>
            to generate the new key with new password.
          </p>

          <Form.Item
            name="pisig_agree"
            rules={[{ required: true, message: "Please agree to the terms." }]}
          >
            <Radio.Group>
              <Radio value="true">
                I have reviewed and approved the subject data.
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Sign
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <>
          <h4>You must create your secret key in order to sign</h4>
          <p>
            <ul>
              <li>
                Please create a password for the key which will allow you to
                sign the subjects.
              </li>
              <li>
                Please keep the password safe and do not share with anyone.
              </li>
              <li>
                Remember the password as it will be required to sign subjects in
                the future.
              </li>
              <li>
                Passwords expire in 90 days after which you need to generate a
                new one for security.
              </li>
              <li>Passwords cannot be retrieved.</li>
              <li>
                Passwords must be a minimum of 8 characters including at least
                one number, one lowercase letter, and one capital letter.
              </li>
            </ul>
          </p>
          <Form
            layout="vertical"
            onFinish={async (values) => {
              const trimmedPassword = values.key_password.trim();
              const trimmedPasswordConfirm = values.key_password_confirm.trim();
              await handleCreateKey({
                ...values,
                key_password: trimmedPassword,
                key_password_confirm: trimmedPasswordConfirm,
              });
            }}
          >
            <Form.Item
              label="Key Password"
              name="key_password"
              rules={[
                { required: true, message: "Please enter your key password." },
                { min: 8, message: "Password must be at least 8 characters." },
                {
                  pattern: /[0-9]/,
                  message: "Password must contain at least one number.",
                },
                {
                  pattern: /[a-z]/,
                  message:
                    "Password must contain at least one lowercase letter.",
                },
                {
                  pattern: /[A-Z]/,
                  message:
                    "Password must contain at least one uppercase letter.",
                },
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>

            <Form.Item
              label="Key Password Confirm"
              name="key_password_confirm"
              dependencies={["key_password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your key password.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      getFieldValue("key_password").trim() === value.trim()
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!",
                      ),
                    );
                  },
                }),
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create Key
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </>
  );
};

export default RenderPiSignatureForm;
