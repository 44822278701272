import {Spin, Steps} from 'antd';
import {SiteSelector} from './SiteSelector';
import {SubjectsSelector} from './SubjectsSelector';
import {EventTypesSelector} from './EventTypesSelector';
import {DateRangeSelector} from './DateRangeSelector';

export const AuditTrailDialog = (props) => {
  const {
    protocolId,
    siteData,
    step,
    isDataDownloadInProgress,
    setIsDataDownloadInProgress,
  } = props;

  const steps = [
    {
      title: 'Select Site',
      content: (<SiteSelector siteData={siteData}/>),
    },
    {
      title: 'Select Subjects',
      content: (
        <SubjectsSelector
          protocolId={protocolId}
          setIsDataDownloadInProgress={setIsDataDownloadInProgress}
        />
      ),
    },
    {
      title: 'Select Event Types',
      content: (<EventTypesSelector protocolId={protocolId}/>),
    },
    {
      title: 'Select Date Range and Submit',
      content: (<DateRangeSelector protocolId={protocolId}/>),
    },
  ];

  const items = steps.map(item => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <div className='AuditTrailDialog'>
      <Spin spinning={isDataDownloadInProgress} >
        <Steps
          current={ step }
          items = { items }
        />
        { steps[step].content }
      </Spin>
    </div>
  );
};
