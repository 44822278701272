import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Table,
  Popover,
  Button,
  Spin,
  Modal,
  Alert,
} from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { createPiSignatureUserKey, piSignatureSignoffForSubject } from "../../lib/fetchers/restApiHttpFetchers";
import { UserRole } from "../../lib/roleUtils";
import SignatureDetails from './SignatureDetails';
import StatusSummary from './StatusSummary';
import RenderPiSignatureForm from './RenderPiSignatureForm';
import CustomFilterDropdown from './CustomFilterDropdown';
import LazyPopoverContent from './LazyPopoverContent';
import {loadSitePiSigTableContent} from "../../legacy/LegacyFacade";
import debounce from "lodash.debounce";
import "./SitePiSignatureContent.scss";

const TABLE_SCROLL_HEIGHT = 500;
const POPOVER_CLICK_DEBOUNCE_DELAY = 300;

const STATUS_FAILED = 'Failed to Sign';
const STATUS_SIGNED = 'Signed & Verified';

const isUserPI = (userRoles) => {
  return userRoles.includes(UserRole.PI);
};

const transformData = (piSigData) => {
  return piSigData.map((subject, index) => {
    const unsignedCrfs = subject.unsigned_record_list;
    const signedCrfs = subject.signed_record_list;
    return {
      key: subject.subject_id ? `${subject.subject_id}` : `subject-${index}`,
      dateUpdated: subject.date_updated,
      subject: subject.subjectDisplayName,
      status: subject.status === "Not Signed" ? "Unsigned" : subject.status,
      unSignedCount: unsignedCrfs.length,
      signedCount: signedCrfs.length,
      unsignedCrfs,
      signedCrfs,
    };
  });
};

const SitePiSignatureContent = (props) => {
  const { siteContext, piSigData, userRoles, userEmail } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    status: [],
  });
  const [visibleData, setVisibleData] = useState([]);
  const [keyData, setKeyData] = useState({
    userHasKey: false,
    generatedTime: "",
    publicKey: "",
  });
  const [signError, setSignError] = useState(null);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [signing, setSigning] = useState(false);
  const [signDetails, setSignDetails] = useState(null);

  const dataRef = React.useRef([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const transformedData = transformData(piSigData.data);
        dataRef.current = transformedData;
        setData(transformedData);
        setVisibleData(transformedData);
      } catch (error) {
        console.error("Error transforming data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [piSigData]);

  const handleSign = async (values) => {
    const pisigDateUpdatedPerSubject = data.reduce((acc, subject) => {
      acc[subject.key] = subject.dateUpdated;
      return acc;
    }, {});
  
    setSigning(true);
  
    try {
      const context = createSignContext(values, pisigDateUpdatedPerSubject);
      const response = await piSignatureSignoffForSubject(context);
  
      if (response.status === 200) {
        processSignResponse(response);
      } else {
        setSignDetails({
          status: STATUS_FAILED,
          errorList: response.errorList,
        });
      }
    } catch (error) {
      console.error("Error in handleSign:", error);
      setSignError("Error signing subjects. Please try again.");
    } finally {
      setSigning(false);
      loadSitePiSigTableContent();
    }
  };
  
  const createSignContext = (values, pisigDateUpdatedPerSubject) => ({
    protocolId: siteContext.protocolId,
    siteId: siteContext.siteId,
    password: values.key_password,
    signerText: 'I have reviewed and approved the subject data.',
    pisigDateUpdatedPerSubject,
  });
  
  const processSignResponse = (response) => {
    const hasErrors = response.data.some(subject => !subject.success);
    const signedCount = response.data.filter(subject => subject.success).length;
    const errorList = [...new Set(response.data
      .filter(subject => !subject.success)
      .flatMap(subject => subject.errorList)
    )];
  
    setSignDetails({
      status: hasErrors ? STATUS_FAILED : STATUS_SIGNED,
      signedCount: signedCount,
      unSignedCount: visibleData.filter(record => record.status !== STATUS_SIGNED).length,
      totalSubjects: response.data.length,
      errorList: errorList,
    });
  };

  const handleForgetPassword = () => {
    setShowPasswordForm(true);
    setKeyData({
      userHasKey: false,
      generatedTime: "",
      publicKey: "",
    });
  };

  const applyFilters = useCallback((filters) => {
    const statusFilters = filters?.status || [];
    if (statusFilters.length === 0) {
      return dataRef.current;
    }
    return dataRef.current.filter((item) => {
      if (statusFilters.includes("Other")) {
        return !["Signed & Verified", "Signed but data has changed. Requires re-signing", "Unsigned"].includes(item.status);
      }
      return statusFilters.includes(item.status);
    });
  }, []);
  
  const filterDataAsync = useCallback(
    async (filters) => {
      const filterFunction = () => applyFilters(filters);
      return new Promise((resolve) => {
        if ("requestIdleCallback" in window) {
          requestIdleCallback(() => resolve(filterFunction()));
        } else {
          setTimeout(() => resolve(filterFunction()), 1);
        }
      });
    },
    [applyFilters],
  );

  const handlePopoverClick = useMemo(
    () => debounce(() => {
    }, POPOVER_CLICK_DEBOUNCE_DELAY),
    [],
  );

  const handleFilterChange = useCallback(
    async (pagination, filters, sorter) => {
      setActiveFilters(filters);
      try {
        const filteredResults = await filterDataAsync(filters);
        setVisibleData(filteredResults);
      } finally {
        setFilterLoading(false);
      }
    },
    [filterDataAsync],
  );

  const handleCreateKey = async (values) => {
    try {
      const context = {
        protocolId: siteContext.protocolId,
        siteId: siteContext.siteId,
        password: values.key_password,
      };
      const response = await createPiSignatureUserKey(context);
      setKeyData({
        userHasKey: response.data.userHasKey,
        generatedTime: response.data.generatedTime,
        publicKey: response.data.publicKey,
      });
      setSignError(null);
      setShowPasswordForm(false);
    } catch (error) {
      console.error("Error in handleCreateKey:", error);
      setSignError("Error creating key. Please try again.");
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
        align: "left",
        render: (text) => <div className="left-aligned-content">{text}</div>,
      },
      {
        title: "Signature Status",
        dataIndex: "status",
        key: "status",
        align: "left",
        filterDropdown: (props) => (
          <CustomFilterDropdown {...props} setActiveFilters={setActiveFilters} />
        ),
        filteredValue: activeFilters.status,
        render: (text) => (
          <div className="status-column">
            {text === "Signed & Verified" ? (
              <>
                <span className="glyphicon glyphicon-certificate custom-glyphicon-certificate" aria-hidden="true"/>&nbsp;{text}
              </>
            ) : (
            <>
               <span className="glyphicon glyphicon-remove-sign" aria-hidden="true"/>&nbsp;{text}
            </>
            )}
          </div>
        ),
      },
      {
        title: "Record Details",
        dataIndex: "details",
        key: "details",
        align: "left",
        render: (text, record) => (
          <div className="details-column">
            <p className="details-text">
              Unsigned records: {record.unSignedCount || 0}
              {record.unsignedCrfs?.length > 0 && (
                <Popover
                  content={<LazyPopoverContent record={record} recordType="unsigned" />}
                  title="Unsigned records"
                  trigger="click"
                  onClick={handlePopoverClick}
                >
                  <Button
                    type="link"
                    icon={<FileTextOutlined />}
                    className="popover-button"
                    aria-label="View unsigned records"
                  />
                </Popover>
              )}
            </p>
            <p className="details-text">
              Signed records: {record.signedCount || 0}
              {record.signedCrfs?.length > 0 && (
                <Popover
                  content={<LazyPopoverContent record={record} recordType="signed" />}
                  title="Signed records"
                  trigger="click"
                  onClick={handlePopoverClick}
                >
                  <Button
                    type="link"
                    icon={<FileTextOutlined />}
                    className="popover-button"
                    aria-label="View signed records"
                  />
                </Popover>
              )}
            </p>
          </div>
        ),
      },
    ],
    [activeFilters, handlePopoverClick],
  );

  const signedCount = data.filter(subject => subject.success || subject.status === "Signed & Verified").length;
  const unSignedCount = data.filter(
    (subject) =>
      (subject.success === false ||
      subject.status === "Unsigned") &&
      (subject.unsignedCrfs && subject.unsignedCrfs.length > 0),
  ).length;
  const totalUnsignedCount = data.filter(
    (subject) =>
      subject.success === false ||
      subject.status === "Unsigned",
  ).length;
  const signedButChangedCount = data.filter(
    (item) =>
      item.status === "Signed but data has changed. Requires re-signing",
  ).length;
  const totalSubjects = data.length;
  const publicKeyId = keyData.publicKey || piSigData.public_key_id;
  const userKey = keyData.userHasKey || piSigData.userKey;
  const keyGeneratedTime = keyData.generatedTime || piSigData.key_generated_time;

  useEffect(() => {
    if (signedCount > 0
      && unSignedCount === 0
      && signedButChangedCount === 0
    ) {
      setSignDetails({
        status: STATUS_SIGNED,
        signedCount,
      });
    } else {
      setSignDetails(null);
    }
  }, [signedCount, unSignedCount, signedButChangedCount, publicKeyId]);

  return (
    <div className="site-pi-sig-content">
      <h4>PI Site Signature</h4>
      <ul>
        <li>
          Subject data to be signed includes Visit, Visit Procedures, Subject
          Logs and Exceptions.
        </li>
        <li>Only the Primary Investigator can sign.</li>
        <li>
          Any subject data change after signing will update the signature status
          to "Unsigned" requiring the subject to be re-signed.
        </li>
        <ul>
          <li>Signing from this page signs all data for all patients.</li>
          <li>
            PI signature manifestation and audit logs can be found on the
            subjects' PI Signature page.
          </li>
        </ul>
      </ul>
      <Modal
        open={signing}
        footer={null}
        closable={false}
        centered
        title="Signing in progress..."
        className="site-pi-sig-content"
      >
        <div className="site-pi-sig-modal-body">
          PI Signature is being recorded for all subjects in this site.
          This may take several minutes to complete.
          You may not exit this page until all subjects have successfully been signed.
          Use Google Chrome or Microsoft Edge to minimize the risk of browser timeouts.
        </div>
        <div className="site-pi-sig-modal-body">
          <Spin size="large" />
        </div>
      </Modal>
      <Modal
        open={loading}
        footer={null}
        closable={false}
        centered
        title="The PI Signature page may take several minutes to load."
        className="site-pi-sig-content"
      >
        <div className="site-pi-sig-modal-body">
          Use Google Chrome or Microsoft Edge to minimize the risk of browser timeouts.
          Pressing the back button will cancel the page load.
        </div>
        <div className="site-pi-sig-modal-body">
          <Spin size="large" />
        </div>
      </Modal>
      {!loading && (
        <>
          <StatusSummary
            signedCount={signedCount}
            signedButChangedCount={signedButChangedCount}
            unSignedCount={totalUnsignedCount}
            totalSubjects={totalSubjects}
          />
          <Table
            columns={columns}
            dataSource={visibleData}
            pagination={false}
            scroll={{ y: TABLE_SCROLL_HEIGHT }}
            sticky
            onChange={handleFilterChange}
            loading={{
              spinning: filterLoading,
              tip: "Filtering data...",
            }}
          />
          {isUserPI(userRoles) &&
            (unSignedCount > 0 || signedButChangedCount > 0) &&
            RenderPiSignatureForm({
              hasPisigSignPermission: isUserPI(userRoles),
              userEmail,
              publicKeyId,
              userKey,
              keyGeneratedTime,
              lastKeyGeneratedTime: piSigData.last_key_generated_time,
              handleCreateKey,
              handleSign,
              handleForgetPassword,
              showPasswordForm,
            })}
        </>
      )}
      {signDetails && (
        <SignatureDetails
          status={signDetails.status}
          signedCount={signDetails.signedCount}
          unSignedCount={signDetails.unSignedCount}
          errorList={signDetails?.errorList}
        />
      )}
      {signError && <Alert message={signError} type="error" />}
      {!isUserPI(userRoles) && totalSubjects === 0 && (
        <div>
          No PI Signature Data Available for this Subject. Please check back
          later.
        </div>
      )}
    </div>
  );
};

export default SitePiSignatureContent;
