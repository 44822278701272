import {getSubjects} from '../../lib/fetchers/restApiHttpFetchers';

/**
 * Wraps a passed function with an error handler
 * @returns {void}
 * */
export const errorHandlerWrapper = async (fn, args) => {
  try {
    await fn.apply(this, Array.isArray(args) ? args : [args]);
  } catch(error) {
    throw error;
  }
};

/**
 * Gets subjects and across selected sites and sorts them by display_id
 * @returns {void}
 * */
export const prepareSubjectsForDisplay = async (setIsDataDownloadInProgress, siteIds, protocolId, setSubjectRecords) => {
  setIsDataDownloadInProgress(true);
  const requests = siteIds.map(siteId => getSubjects({protocolId, siteId}));
  const results = await Promise.all(requests);
  const sortedSubjectRecords = results.flat();
  sortedSubjectRecords.sort((a, b) => a.display_id[0].value.localeCompare(b.display_id[0].value));
  setSubjectRecords(sortedSubjectRecords);
  setIsDataDownloadInProgress(false);
};

/**
 * Read chunks from reader until all chunks are read.
 * @param reader - reader containing chunks to read
 * @return chunks - read chunks from reader
* */
export const aggregateFileChunks = async (reader) => {
  const chunks = [];

  let done;
  let value;

  while (!done) {
    ({ value, done } = await reader.read());
    if (done) {
      return chunks;
    }
    chunks.push(value);
  }
};
