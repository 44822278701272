import {Button, Modal} from 'antd';

export const FileDownloadInProgressModal = ({ isFileDownloadInProgress, protocolId, handleClose, title }) => {
  return (
    <Modal
      className='ReportModal'
      maskClosable={false}
      footer={[
        <Button
          key="back"
          data-modal-cancel-button-id={protocolId}
          onClick={handleClose}
        >
          Close
        </Button>,
      ]}
      title={<h2>{title}</h2>}
      open={isFileDownloadInProgress}
      onCancel={handleClose}
    >
      The report is being generated, and will automatically download to your browser when complete.
      You may click “Close” and navigate to other Vessel pages in the meantime, as it will not interfere with the report download.
      <br />
      <p><b>Do not refresh or close this page, and do not log out of your current session.</b> This will prevent the report from downloading.</p>
      <br />
      <p>If the download fails, an error pop-up will show.</p>
    </Modal>
  );
};
